






















import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import QRcode from 'qrcode';

type TextOptions = Partial<{
  font: string;
  x: number;
  y: number;
  color: string;
  maxLength: number;
}>;

type ImageOptions = Partial<{
  x: number;
  y: number;
}>;

@Component
export default class PayQrcode extends Vue {
  @Prop() qrcodeVisible!: boolean;
  @Prop() qrcodeData!: {
    projectName: string;
    orderAmount: number;
    qrCodeUrl: string;
    status: string;
  };

  canvas: HTMLCanvasElement | null = null;
  ctx: CanvasRenderingContext2D | null = null;
  url = '';

  padding = 12;

  getCanvas(): void {
    this.canvas = document.getElementById('canvas') as HTMLCanvasElement;
    this.ctx = this.canvas.getContext('2d') as CanvasRenderingContext2D;
    this.ctx.font = '18px SourceHanSansCN';
    this.canvas.height =
      650 +
      Math.floor(
        this.ctx.measureText(this.qrcodeData.projectName).width / 240
      ) *
        24;
    this.ctx.fillStyle = '#FFFFFF';
    this.ctx.fillRect(0, 0, this.canvas.width, this.canvas.height);
  }

  drawRect(): void {
    if (!this.canvas || !this.ctx) return;
    this.ctx.lineWidth = 2;
    this.ctx.strokeStyle = '#2944B1';
    const r = 8;
    const x = this.padding;
    const y = this.padding;
    const width = this.canvas.width - this.padding * 2;
    const height = this.canvas.height - this.padding * 2;
    this.ctx.beginPath();
    this.ctx.moveTo(x + r, y);
    this.ctx.arcTo(x + width, y, x + width, y + height, r);
    this.ctx.arcTo(x + width, y + height, x, y + height, r);
    this.ctx.arcTo(x, y + height, x, y, r);
    this.ctx.arcTo(x, y, x + r, y, r);
    this.ctx.stroke();
  }

  async getUrl(): Promise<void> {
    this.url = await QRcode.toDataURL(this.qrcodeData.qrCodeUrl, {
      margin: 0,
      width: 330,
    });
  }

  createImg(url: string): Promise<HTMLImageElement> {
    const img = new Image();
    return new Promise((resolve) => {
      img.onload = function () {
        resolve(img);
      };
      img.src = url;
    });
  }

  drawImage(img: HTMLImageElement, options: ImageOptions = {}): void {
    const { x = 0, y = 0 } = options;
    if (!this.ctx) return;
    this.ctx.drawImage(img, x, y, img.width, img.height);
  }

  drawText(text: string, options: TextOptions = {}): number {
    const {
      font = 'bold 18px SourceHanSansCN',
      color = '#3C3C3C',
      x = 0,
      y = 0,
      maxLength = 330,
    } = options;
    if (!this.ctx) return 0;
    this.ctx.font = font;
    this.ctx.fillStyle = color;
    const strArray = [''];
    for (let i = 0; i < text.length; i++) {
      if (
        this.ctx.measureText(strArray[strArray.length - 1]).width < maxLength
      ) {
        strArray[strArray.length - 1] += text[i];
      } else {
        strArray.push('');
      }
    }
    strArray.forEach((str, i) => {
      this.ctx?.fillText(str, x, y + 24 * i);
    });
    return (strArray.length - 1) * 24 + y;
  }

  async draw(): Promise<void> {
    if (!this.canvas || !this.ctx) return;
    const titleImg = await this.createImg(require('@/assets/img/title.png'));
    this.drawImage(titleImg, {
      x: (this.canvas.width - titleImg.width) / 2,
      y: 54 + this.padding,
    });
    const tipImg = await this.createImg(require('@/assets/img/tip.png'));
    this.drawImage(tipImg, {
      x: (this.canvas.width - tipImg.width) / 2,
      y: 112 + this.padding,
    });
    const dashImg = await this.createImg(require('@/assets/img/dash.png'));
    this.drawImage(dashImg, {
      x: (this.canvas.width - dashImg.width) / 2,
      y: 161 + this.padding,
    });
    const payImg = await this.createImg(this.url);
    this.drawImage(payImg, {
      x: (this.canvas.width - payImg.width) / 2,
      y: 186 + this.padding,
    });
    const logoImg = await this.createImg(require('@/assets/img/icon.png'));
    this.drawImage(logoImg, {
      x: (this.canvas.width - logoImg.width) / 2,
      y: 186 + (payImg.height - logoImg.height) / 2 + this.padding,
    });
    this.drawText('项目名称：', {
      x: (this.canvas.width - payImg.width) / 2,
      y: 554 + this.padding,
    });

    const y = this.drawText(this.qrcodeData.projectName, {
      x:
        (this.canvas.width - payImg.width) / 2 +
        this.ctx.measureText('项目名称：').width,
      y: 554 + this.padding,
      maxLength: payImg.width - this.ctx.measureText('项目名称：').width,
    });
    this.drawText(
      (this.qrcodeData.status === 'TO_BE_CONFIRMED' ? '首款' : '尾款') +
        '金额：￥' +
        this.qrcodeData.orderAmount,
      {
        x: (this.canvas.width - payImg.width) / 2,
        y: y + 34,
        color: '#fc6a4f',
      }
    );
  }

  @Watch('qrcodeVisible')
  watchVisible(val: boolean): void {
    if (!val) return;
    this.$nextTick(async () => {
      this.getCanvas();
      this.drawRect();
      await this.getUrl();
      await this.draw();
      this.download();
    });
  }

  download(): void {
    this.canvas?.toBlob((blob) => {
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = this.qrcodeData.projectName;
      a.click();
      a.remove();
      URL.revokeObjectURL(url);
    });
  }

  closeModal(): void {
    this.ctx?.clearRect(
      0,
      0,
      this.canvas?.width || 0,
      this.canvas?.height || 0
    );
    this.$emit('update:qrcodeVisible', false);
  }
}
